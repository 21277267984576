import { combineReducers } from "redux";

import Current from "./Current";
import Employee from "./Employee";
import Type from "./Type";
import Specialization from "./Specialization";
import Direction from "./Direction";
import Region from "./Region";
import Country from "./Country";

export default combineReducers({
  Current,
  Employee,
  Type,
  Specialization,
  Direction,
  Region,
  Country,
});
