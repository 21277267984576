import * as Action from "@actions/index";
// Asia/United_Arab_Emirates
const InitialState = {
  status: "waiting",
  timezone: {
    main: {
      format: "Europe/Moscow",
      name: {
        en: "MSK",
        ru: "МСК",
        // de: "MZ",
      },
    },
    msk: {
      format: "Asia/Dubai",
      name: {
        en: "GST",
        ru: "GST",
        // de: "MESZ",
      },
    },
  },
  dates: {
    registrationStart: "",
    broadcastsStart: "2022-11-16T10:00:00+03:00",
    registrationEnd: "",
    networkingDays: [],
  },
  error: "",
};

export default function Event(state = InitialState, action: any) {
  switch (action.type) {
    case Action.Event.SET_STATUS:
      return { ...state, status: action.data };
    case Action.Event.SET_ERROR:
      return { ...state, error: action.data };
    case Action.Event.CLEAR:
      return { ...state, ...InitialState };
    default:
      return state;
  }
}
